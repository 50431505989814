<template>
  <v-container class="pa-0" fluid>
    <div>
      <div id="v-step-0"></div>
      <v-tour name="myTour" :steps="steps"></v-tour>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-4">
          <img class="avatar-nick" src="../img/sou-nick.png" />
        </div>
        <div class="col-8">
          Olá João, eu sou o NICK
          <div class="row">
            <div class="col-8">
              <v-text-field
                placeholder="Falar com nick"
                dense
                color="white"
              ></v-text-field>
            </div>
            <div class="col-3">
              <v-btn type="submit" color="secondary2" @click="goAssistente()">
                <v-icon small color="white">fas fa-share</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" id="v-step-1">
          <v-badge
            :content="9"
            style="display: block"
            bordered
            color="secondary2"
            overlap
          >
            <div class="box-main">
              <p class="title">Avisos</p>
              <!-- lista e avisos -->
              <div class="row">
                <div class="col-2">
                  <span class="sub-title aviso-status">Novo</span>
                </div>
                <div class="col-2">
                  <span class="sub-title">16/05</span>
                </div>
                <div class="col-8">
                  <span class="sub-title">Vencimento da Vacina do Enzo</span>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <span class="sub-title aviso-status">Visto</span>
                </div>
                <div class="col-2">
                  <span class="sub-title">25/11</span>
                </div>
                <div class="col-8">
                  <span class="sub-title">Workshop - alimentos saudaveis</span>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <span class="sub-title aviso-status">Novo</span>
                </div>
                <div class="col-2">
                  <span class="sub-title">30/12</span>
                </div>
                <div class="col-8">
                  <span class="sub-title">Comite de são silvestre</span>
                  <span class="sub-title aviso-status ml-3"
                    >Ver <v-icon small color="secondary2">fas fa-plus</v-icon>
                  </span>
                </div>
              </div>
            </div>
          </v-badge>
        </div>
        <div class="col-12" id="v-step-2">
          <div class="box-main">
            <p class="title">Dicas do Nick</p>
            <div class="row">
              <div class="col-6">
                <div class="box-recomendacao-nick">
                  <span class="recomendacao-title" style="display: block"
                    >Vacina</span
                  >
                  <span
                    class="recomendacao-text"
                    style="display: block; font-size: 12px; font-weith: bold"
                    >febre amarela...</span
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="box-recomendacao-nick">
                  <span class="recomendacao-title" style="display: block"
                    >Artigos</span
                  >
                  <span
                    class="recomendacao-text"
                    style="display: block; font-size: 10px; font-weith: bold"
                    >Como emagrecer de...</span
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="box-recomendacao-nick">
                  <span class="recomendacao-title" style="display: block"
                    >Dicas</span
                  >
                  <span
                    class="recomendacao-text"
                    style="display: block; font-size: 12px; font-weith: bold"
                    >9 alimentos para...</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="box-main">
            <div style="display: flex; justify-content: space-between">
              <p class="title">Últimos atendimentos</p>
              <p class="title" @click="gochamados()">
                <span class="sub-title aviso-status ml-3"
                  >Ver <v-icon small color="secondary2">fas fa-plus</v-icon>
                </span>
              </p>
            </div>

            <div class="row">
              <div class="col-6 col-sm-4">
                <div class="box-ultimos-atendimento">
                  <p class="recomendacao-title" style="text-align: center">
                    #10013
                  </p>
                  <p class="recomendacao-text" style="text-align: center">
                    Aberto
                  </p>
                </div>
              </div>
              <div class="col-6 col-sm-4">
                <div class="box-ultimos-atendimento">
                  <p class="recomendacao-title" style="text-align: center">
                    #10013
                  </p>
                  <p class="recomendacao-text" style="text-align: center">
                    Fechado
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="box-main">
            <p class="title">Serviços mais acessados</p>
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <div class="col-12">
                    <span class="sub-title"
                      >Formulário 2° via da carteirinha</span
                    >
                  </div>
                  <div class="col-12">
                    <span class="sub-title">Rede credenciada do meu plano</span>
                  </div>
                  <div class="col-12">
                    <span class="sub-title"
                      >Formulário de remoção de dependentes</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-4 box-faq">
                <div class="faq title">FAQ</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <v-btn block @click="$router.push('/ajuda')">
            Ir Para Ajuda 1
          </v-btn>
        </div>
      </div>

    </div>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  components: {},
  mounted() {
    this.$tours["myTour"].start();
  },
  methods: {
    ...mapMutations(["showmsg", "updateBadges"]),
    goAssistente() {
      window.location.href =
        "https://app.engati.com/static/standalone/bot.html?bot_key=0afb504e29214edf&env=p";
    },
    gochamados() {
      this.$util.link("atendimentos");
    }
  },
  computed: {
    ...mapState(["badges"]),
  },
  data: () => ({
    items: [],
    steps: [
      {
        target: "#v-step-0",
        header: {
          title: "Olá, João",
        },
        content: `<strong>Vamos fazer um Tour?</strong>!`,
      },
      {
        target: "#v-step-1",
        content: "Aqui voce acompanha tudo os avisos recentes para você.",
      },
      {
        target: "#v-step-2",
        content: "As dicas do Nick são para melhorar ainda mais a sua saúde.",
        params: {
          placement: "top",
        },
      },
    ],
  }),
};
</script>
<style>
.faq {
  background: #fff;
  padding: 35px;
  border-radius: 7px;
}

.box-faq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aviso-ver-mais {
  padding: 5px;
  border: 1px solid #333 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aviso-status {
  padding: 5px;
  border: 1px solid #333 !important;
  border-radius: 5px;
}

.box-main {
  background: #eee;
  padding: 5px;
  border-radius: 7px;
}

.title {
  color: #2a244e;
  font-weight: bold;
}
.sub-title {
  color: #666;
  font-weight: bold;
  font-size: 12px;
}

.recomendacao-title {
  color: #2a244e;
  font-weight: bold;
}
.recomendacao-text {
  color: #666;
  font-weight: bold;
}

.box-acoes-alerta {
  width: 25px;
  height: 25px;
  position: absolute;
  margin-top: -56px;
}

.title-recomendacoes-nick {
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.box-recomendacao-nick {
  background: #fff;
  padding: 30px;
  border-radius: 7px;
}

.box-ultimos-atendimento {
  background: #fff;
  padding: 5px;
  border-radius: 7px;
}

.title-ultimos-atendimentos {
  font-size: 15px;
  color: #ea4965;
  font-weight: bold;
}

.box-mais-acessados-main {
  padding-top: 0px !important;
  padding-right: 0px;
  padding-left: 13px !important;
  padding-bottom: 10px !important;
  color: #666;
}

.box-mais-acessados {
  border: solid 1px #eee;
  padding: 5px;
}

.title-mais-acessados {
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.avatar-nick {
  width: 100px;
  height: 100px;
  border-radius: 40%;
}

.box-acoes {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  padding: 10px;
  border-radius: 7px;
}

.by-thinkcare {
  font-size: 10px;
  color: #ea4965;
  font-weight: 600;
  display: block;
}
.name-nick {
  font-size: 15px;
  display: block;
}
</style>
